<template>
  <div>
    <div class="center">
      <h2 class="heading-title my-3">
        Your Purchase Details
      </h2>
    </div>
    <table cellpadding="5"
           width="100%"
           cellspacing="0"
           align="left"
           border="1"
    >
      <thead>
        <tr>
          <th>Product</th>
          <th>Price</th>
          <th>Qty</th>
          <th>Discount(%)</th>
          <th>Subtotal</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{{ title }}</td>
          <td><span class="dollar">$</span>{{ price }}</td>
          <td>1</td>
          <td>{{ discount }}</td>
          <td><span class="dollar">$</span>{{ subtotal }}</td>
        </tr>
      </tbody>
    </table>
    <br>
    <br>

    <div style="margin-top: 6%;">
      <select v-model="selectCouponReferal" class="select-coupon" @change="selectCoupon">
        <option :value="null" disabled>
          Select Option
        </option>
        <option value="coupon" selected>
          Coupon
        </option>
        <option value="promotioncode">
          Referral Coupons
        </option>
      </select>
      <span v-show="showRefferalText" style="color:red">You can add promotional code in next step!!</span>
      <span v-show="showCouponBox">
        <input v-model="coupon"
               required
               name="coupon"

               placeholder="Coupon code"
               type="text"
               autocomplete="off"
               style="border-bottom: 1px solid #212121;"
        >
        <v-btn
          color="lighten-2"
          dark
          @click="applyCoupon"
        >
          Apply Coupon
        </v-btn>
      </span>
    </div>
    <br>
    <br>
    <div style="width:50%;float:left" />
    <div style="width:50%;float:right">
      <h3>Final Subscription Details</h3>
      <table cellpadding="5"
             width="100%"
             cellspacing="0"
             align="left"
             border="1"
             style="margin-top:3%"
      >
        <tbody>
          <tr>
            <td>Subtotal</td>
            <td><span class="dollar">$</span>{{ subtotal }}</td>
          </tr>
          <tr v-if="sendCoupon">
            <td colspan="2" style="color:green">
              <span>coupon discount added</span>
            </td>
          </tr>
          <tr>
            <td>Total</td>
            <td><span class="dollar">$</span>{{ total }}</td>
          </tr>
        </tbody>
      </table>

      <v-btn
        color="lighten-2 checkout"
        dark
        class="mt-3"
        @click="checkout"
      >
        <h2>Proceed to Checkout </h2>
      </v-btn>
    </div>
  </div>
  </div>
</template>

<script>

// import { StripeCheckout } from '@vue-stripe/vue-stripe';
import axios from 'axios';
import { loadStripe } from "@stripe/stripe-js"
// const stripeInit = loadStripe('pk_test_51ISdPxHUlEkmhxVybIiacV1cwz5fIqXwQUKBNKSrzGFyHfRBf8VUIJFWRpTUgxSi3tvuZ7dB5vagdtYiXM9GZSFv008O712IMx')
const stripeInit = loadStripe(process.env.VUE_APP_STRIPE_KEY)
export default {
  components: {
    // StripeCheckout,
  },

  data () {
    return {
      price:null,
      title:null,
      discount:null,
      subtotal: null,
      loading: false,
      coupon: null,
      sendCoupon: null,
      selectCouponReferal: null,
      showCouponBox: true,
      total: null,
      showRefferalText: false,
    };
  },
  async mounted(){
    // console.log("userId::", localStorage.getItem('userId'))
    // THIS IS WHERE WE ARE
    console.log(this.$route.query.id);
    localStorage.removeItem('redirect');
    localStorage.removeItem('footerPopup');
    if(this.$route.query.id !== "undefined"){
        const response = await axios.get(process.env.VUE_APP_API+'course/'+this.$route.query.id+'/chapters');
        if(response.data.success){
          let grades = response.data.data.course;
          this.price = grades.actual_price
          this.title = grades.title
          if(grades.discount){
            // console.log("test::", this.subtotal, this.discount)
            this.discount = grades.discount
            // let st = (this.price) * (this.discount/ 100)
            // st = this.price - st
            this.subtotal = grades.sale_price
          } else {
            this.subtotal = grades.actual_price
          }
          this.total =  this.subtotal;
        }
    }
  },
  created (){
			// Store mutations
	    this.$store.commit('navigation/resetState');
      this.$store.commit('navigation/userDashboard', false);
      this.$store.commit('navigation/titleStatus', false);
      this.$store.commit('navigation/replaceCart', true);
      this.$store.commit('navigation/changeTitle', 'Your Cart');
      this.$store.commit('navigation/replaceLeftArrow', true);
  },
  methods: {
    async checkout () {
      try{
      let checkoutUrl = process.env.VUE_APP_API+'checkout/'+localStorage.getItem('userId')+'/course/'+this.$route.query.id;
      // console.log("selectCouponReferal::",this.selectCouponReferal)
      if(this.selectCouponReferal == 'promotioncode'){
        checkoutUrl = process.env.VUE_APP_API+'checkout/'+localStorage.getItem('userId')+'/course/'+this.$route.query.id+'/promotioncode';
      } else {
        if(this.sendCoupon !== null){
          checkoutUrl = process.env.VUE_APP_API+'checkout/'+localStorage.getItem('userId')+'/course/'+this.$route.query.id+'/coupon/'+this.sendCoupon
        }
      }
      const response = await axios.get(checkoutUrl, { headers: {Authorization: 'Bearer ' + localStorage.getItem('token')}});
      if(response.data.success){
          console.log(response.data.data.session_id)
          // this.$refs.checkoutRef.redirectToCheckout({sessionId: response.data.data.session_id })
          stripeInit.then(stripe => {
            stripe.redirectToCheckout({
              sessionId: response.data.data.session_id
            }).then(function(result) {
              console.log(result);
            }).catch(function(error) {
              console.error(error);
            });
          })
        } else {
          this.$notify({
                    group: 'notify',
                    type: 'error',
                    title: 'Error!!',
                    text: 'Something went wrong!!'
          });
        }
      } catch(error){
        console.log("error::", error)
        this.$notify({
                    group: 'notify',
                    type: 'error',
                    title: 'Not valid',
                    text: "Something went wrong!!"
          });
      }
      // You will be redirected to Stripe's secure checkout page
      // this.$refs.checkoutRef.redirectToCheckout();
      // this.$refs.checkoutRef.redirectToCheckout({sessionId: 'cs_KmeIFgWSfN5GW6tP2e5IQ0Vb9EA0q3…kW7DwsM6dAbhQ30' })
    },
    async applyCoupon(){
      try{
        let postData = {
          coupon_code: this.coupon,
          user_id: localStorage.getItem('userId'),
          course_id: this.$route.query.id
        }
        // console.log("post::", postData)
        const response = await axios.post(process.env.VUE_APP_API+'check/coupon/course', postData, { headers: {Authorization: 'Bearer ' + localStorage.getItem('token')}});
        if(response.data.success){
          // console.log("res::",response.data.data)
          this.total = response.data.data.coupon.total_after_coupon_discount;
          this.sendCoupon = response.data.data.coupon.coupon_code
          this.$notify({
                    group: 'notify',
                    type: 'success',
                    title: 'success',
                    text: "Coupon apllied successfully!!"
          });
        } else {
          // console.log("er::",response.data)
          this.$notify({
                    group: 'notify',
                    type: 'error',
                    title: 'Error!!',
                    text: response.data.errors[0]
          });
        }
      } catch(error){
        console.log("error::", error)
        this.$notify({
                    group: 'notify',
                    type: 'error',
                    title: 'Not valid',
                    text: "This coupon code is invalid or has expired"
          });
      }
    },
    selectCoupon(val){
      // console.log("val::", this.selectCouponReferal, val.target.value, val)
      if(val.target.value == 'promotioncode'){
        this.showCouponBox = false
        this.sendCoupon = null;
        this.total = this.subtotal;
        this.showRefferalText = true;
      } else {
        this.showCouponBox = true;
        this.showRefferalText = false;
      }
    }
  },



};
</script>

<style>
th, td, tr, table {
border: 1px solid #a9a9a9;
text-align: center;
}

h2.heading-title {
    margin-bottom: 20px;
}

.center {
    text-align: center;
}

button.v-btn.theme--dark.lighten-2.checkout {
    float: right;
    padding: 30px 60px;
    background-color: #850b0b;
}
select.select-coupon {
    border: 1px solid;
    padding: 10px;
    font-size: 17px;
    margin-right: 10px;
}

select.select-coupon {
    border-radius: 3px;
    border: 1px solid #333333;
    padding-left: 3px;
    -webkit-appearance: menulist !important;
    -moze-appearance: menulist !important;
    appearance: menulist !important;
    margin-bottom: 2rem;
}

    span.dollar {
        padding-right: 10px;
    }

</style>
